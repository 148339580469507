import { Component, Input, OnInit } from '@angular/core';
import { UserVacationModel, ApiClient, VacationStatus, VacationType } from '../../../services/api.client';
import { Data } from '@angular/router';
import { first } from 'rxjs/operators';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'vacation-calendar',
  templateUrl: './vacation-calendar.component.html',
  styleUrls: ['./vacation-calendar.component.scss']
})
/** vacation-calendar component*/
export class VacationCalendarComponent implements OnInit {

  readonly DAYS_BEFORE_AFTER = 30;
  readonly CALENDAR_SIZE = 18;
  readonly MS_IN_DAY = (1000 * 3600 * 24);
  readonly DAYS_IN_WEEK = 7;
  readonly COLUMN_WIDTH = 40;

  @Input() vacation: UserVacationModel;

  vacationUserName = "";
  calendarTemplate: IVacCalendarTemplateItem[] = new Array();
  daysWithMonthLabel: Date[] = new Array();
  userVacDataMap: Map<number, IUserVacCalendarData> = new Map();
  currentUserVacData: IUserVacCalendarData;

  lastLabledMonth: Date;

  /** vacation-calendar ctor */
  constructor(private apiClient: ApiClient) {
  }

  ngOnInit() {
    this.fillCalendarTemplate();
    this.calculateDaysWithMonthLabel();

    this.currentUserVacData = <IUserVacCalendarData>{
      name: this.vacation.user.name,
      availableVacCount: 0,
      vacations: new Array<UserVacationModel>(),
      offdays: new Map()
    };

    forkJoin(
      this.apiClient.getUserTeamMembers(),
      this.apiClient.getVacations(this.calendarTemplate[0].date, this.calendarTemplate[this.calendarTemplate.length - 1].date, undefined, true),
      this.apiClient.getUsersAbsenceInfo(this.calendarTemplate[0].date, this.calendarTemplate[this.calendarTemplate.length - 1].date)
    ).subscribe(([teamUsers, vacations, userAbsencesInfo]) => { 
      // Team Members
      teamUsers.forEach(teamUser => {
        if (teamUser.id !== this.vacation.user.id) {
          this.userVacDataMap.set(teamUser.id,
            <IUserVacCalendarData>{
              name: teamUser.name,
              vacations: new Array<UserVacationModel>(),
              availableVacCount: 0,
              offdays: new Map()
            }
          );
        }
      });

      vacations.forEach(item => {
        if (item.user.id !== this.vacation.user.id) {
          if (this.userVacDataMap.has(item.user.id)) {
            this.userVacDataMap.get(item.user.id).vacations.push(item);
          }
        } else {
          this.currentUserVacData.vacations.push(item);
        }
      });

      var userIds = Array.from(this.userVacDataMap.keys());
      userIds.push(this.vacation.user.id);
      //this.apiClient.getUsersAbsenceInfo(this.calendarTemplate[0].date, this.calendarTemplate[this.calendarTemplate.length - 1].date).subscribe(userAbsencesInfo => {
        userAbsencesInfo.forEach(uai => {
          if (uai.userId == this.vacation.user.id) {
            this.currentUserVacData.availableVacCount = uai.availableVacations;

            if (uai.offdays != undefined || uai.offdays != null)
              for (var i = 0; i < uai.offdays.length; i++) {
                this.currentUserVacData.offdays.set(this.calendarTemplate[i].date, uai.offdays[i]);
              }
          } else {
            if (this.userVacDataMap.has(uai.userId)) {
              this.userVacDataMap.get(uai.userId).availableVacCount = uai.availableVacations;

              if (uai.offdays != undefined || uai.offdays != null)
                for (var i = 0; i < uai.offdays.length; i++) {
                  this.userVacDataMap.get(uai.userId).offdays.set(this.calendarTemplate[i].date, uai.offdays[i]);
                }
            }
          }
        })
      //});
    });

    this.enableDragToScroll();
  }

  ngAfterViewInit() {
    const ele = document.getElementById('container');
    ele.scrollLeft = (this.DAYS_BEFORE_AFTER - this.DAYS_IN_WEEK) * this.COLUMN_WIDTH;
  }

  enableDragToScroll() {
    const ele = document.getElementById('container');
    ele.style.cursor = 'grab';
    let pos = { top: 0, left: 0, x: 0, y: 0 };

    const mouseDownHandler = function (e) {
      ele.style.cursor = 'grabbing';
      ele.style.userSelect = 'none';
      ele.style.msUserSelect = 'none';
      ele.style.webkitUserSelect = 'none';

      pos = {
        left: ele.scrollLeft,
        top: ele.scrollTop,
        // Get the current mouse position
        x: e.clientX,
        y: e.clientY,
      };

      document.addEventListener('mousemove', mouseMoveHandler);
      document.addEventListener('mouseup', mouseUpHandler);
    };

    const mouseMoveHandler = function (e) {
      // How far the mouse has been moved
      const dx = e.clientX - pos.x;
      const dy = e.clientY - pos.y;

      // Scroll the element
      ele.scrollTop = pos.top - dy;
      ele.scrollLeft = pos.left - dx;
    };

    const mouseUpHandler = function () {
      ele.style.cursor = 'grab';
      ele.style.removeProperty('user-select');

      document.removeEventListener('mousemove', mouseMoveHandler);
      document.removeEventListener('mouseup', mouseUpHandler);
    };

    // Attach the handler
    ele.addEventListener('mousedown', mouseDownHandler);
  }

  calculateDaysWithMonthLabel() {
    var firstDayOfMonthIdxs: number[] = new Array();

    firstDayOfMonthIdxs.push(0);
    for (let i = 0; i < this.calendarTemplate.length; i++) {
      if (this.calendarTemplate[i].day == 1 && i != 0) {
        firstDayOfMonthIdxs.push(i);
      }
    }
    firstDayOfMonthIdxs.push(this.calendarTemplate.length - 1);

    var offset = 0;
    for (var i = 0; i < firstDayOfMonthIdxs.length - 1; i++) {
      this.daysWithMonthLabel.push(
        this.calendarTemplate[offset + Math.round((firstDayOfMonthIdxs[i + 1] - firstDayOfMonthIdxs[i]) / 2) - 1].date);

      offset = firstDayOfMonthIdxs[i + 1];
    }
  }

  checkDayForMonthLabel(day) {
    if (this.daysWithMonthLabel.includes(day)) {
      this.lastLabledMonth = day;
      return true;
    } else 
        return false;
  }

  checkDayForHiddingMonthLabel(day: Date) {
    var dayBefore = new Date(day.getTime() - this.MS_IN_DAY * 1);
    return this.lastLabledMonth && this.lastLabledMonth.getTime() === dayBefore.getTime();
  }

  getUserIds() {
    return this.userVacDataMap.keys();
  }

  fillCalendarTemplate() {
    var start = new Date(this.vacation.from.getTime() - this.MS_IN_DAY * this.DAYS_BEFORE_AFTER);

    var vacLength = (this.vacation.to.getTime() - this.vacation.from.getTime()) / this.MS_IN_DAY + 1;
    var calendarSize = this.DAYS_BEFORE_AFTER * 2 + vacLength;

    for (var i = 0; i < calendarSize; i++) {
      let calTempIter = <IVacCalendarTemplateItem>{
        day: start.getDate(),
        dayOfWeek: start.toLocaleString('en-us', { weekday: 'short' }).toUpperCase(),
        month: start.toLocaleString('en-us', { month: 'long' }).toUpperCase(),
        date: new Date(start.getFullYear(), start.getMonth(), start.getDate())
      };

      this.calendarTemplate.push(calTempIter);
      start.setDate(start.getDate() + 1);
    }
  }

  getMonthCellClasses(calTemplItem: IVacCalendarTemplateItem) {
    var classes = "";
    var today = new Date();

    if (calTemplItem.date.getDate() === today.getDate() &&
      calTemplItem.date.getMonth() === today.getMonth() &&
      calTemplItem.date.getFullYear() == today.getFullYear()) {
      classes += "vac-today ";
    }

    return classes;
  }

  getCellClasses(calTemplItem: IVacCalendarTemplateItem, userId: number) {
    var clases = "";
    var vacStatus: IVacStatusDef = null;

    const resetTime = (date: Date) => new Date(date.getFullYear(), date.getMonth(), date.getDate());
    if (userId == this.vacation.user.id && resetTime(calTemplItem.date) >= resetTime(this.vacation.from) && resetTime(calTemplItem.date) <= resetTime(this.vacation.to))
      clases += "vac-curr-appr-type ";
    else {
      var vacations = (userId == this.vacation.user.id) ? this.currentUserVacData.vacations : this.userVacDataMap.get(userId).vacations;
      vacStatus = this.getVacationTypeForUserOnDay(vacations, calTemplItem.date);
      switch (vacStatus.status) {
        case VacStatus.Approved:
          clases += "vac-appr-type ";
          break;
        case VacStatus.Waiting:
          clases += "vac-open-type ";
          break;
        case VacStatus.MobileWork:
          clases += "vac-mobile-work-type ";
          break;
        case VacStatus.BusinessTrip:
          clases += "vac-business-trip-type ";
          break;
        default:
      }
    }

    if ((userId == this.vacation.user.id &&
      (calTemplItem.date.getDate() == this.vacation.from.getDate() && calTemplItem.date.getMonth() == this.vacation.from.getMonth() && calTemplItem.date.getFullYear() == this.vacation.from.getFullYear())) ||
      (vacStatus != null && vacStatus.isStart))
      clases += "vac-cell-border-l ";
    if ((userId == this.vacation.user.id &&
      (calTemplItem.date.getDate() == this.vacation.to.getDate() && calTemplItem.date.getMonth() == this.vacation.to.getMonth() && calTemplItem.date.getFullYear() == this.vacation.to.getFullYear())) ||
      (vacStatus != null && vacStatus.isEnd))
      clases += "vac-cell-border-r ";

    var dayoffMap = (userId == this.vacation.user.id) ? this.currentUserVacData.offdays : this.userVacDataMap.get(userId).offdays;
    if (dayoffMap.has(calTemplItem.date) && dayoffMap.get(calTemplItem.date)) {
      clases += "cal-offday ";
    }

    return clases;
  }

  getVacationTypeForUserOnDay(vacations: UserVacationModel[], date: Date): IVacStatusDef {
    var res = <IVacStatusDef>{
      status: VacStatus.NotVacation,
      isStart: false,
      isEnd: false
    }

    const resetTime = (date: Date) => new Date(date.getFullYear(), date.getMonth(), date.getDate());

    for (let v of vacations) {
      if (v.status != VacationStatus.Rejected && date >= resetTime(v.from) && date <= resetTime(v.to)) {
        res.status = (v.status == VacationStatus.Approved || v.status == VacationStatus.Ok) ? VacStatus.Approved : VacStatus.Waiting;

        if (v.type == VacationType.HomeOffice) {
          res.status = VacStatus.MobileWork;
        }
        else if (v.type == VacationType.BusinessTrip) {
          res.status = VacStatus.BusinessTrip;
        }

        res.isStart = date.getDate() == v.from.getDate() && date.getMonth() == v.from.getMonth() && date.getFullYear() == v.from.getFullYear();
        res.isEnd = date.getDate() == v.to.getDate() && date.getMonth() == v.to.getMonth() && date.getFullYear() == v.to.getFullYear();

        break;
      }
    }

    return res;
  }
}

interface IVacCalendarTemplateItem {
  day: number;
  dayOfWeek: string;
  month: string;
  date: Date;
}

interface IUserVacCalendarData {
  name: string;
  availableVacCount: number;
  vacations: UserVacationModel[];
  offdays: Map<Date, boolean>;
}

interface IVacStatusDef {
  status: VacStatus;
  isStart: boolean;
  isEnd: boolean;
}

enum VacStatus {
  Waiting,
  Approved,
  MobileWork,
  BusinessTrip,
  NotVacation
}
